// import ProgressBar from "./ProgressBar";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useUrlStore from "../stores/useUrlStore";
import { db } from "../../Firebase";
import { doc, getDoc } from "firebase/firestore";

const NavBar = () => {
  const [userID, setUserID] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const redirectUrl = useUrlStore((state) => state.redirectUrl);
  const setRedirectUrlListener = useUrlStore((state) => state.setRedirectUrlListener);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const extractedUserID = urlParams.get("userID");
    setUserID(extractedUserID);

    if (extractedUserID) {
      const unsubscribe = setRedirectUrlListener(extractedUserID);
      return () => unsubscribe();
    }
  }, [setRedirectUrlListener]);

  useEffect(() => {
    const themeToggleDarkIcon = document.getElementById(
      "theme-toggle-dark-icon"
    );
    const themeToggleLightIcon = document.getElementById(
      "theme-toggle-light-icon"
    );

    if (
      localStorage.getItem("color-theme") === "dark" ||
      (!("color-theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      themeToggleLightIcon.classList.remove("hidden");
    } else {
      themeToggleDarkIcon.classList.remove("hidden");
    }

    const themeToggleBtn = document.getElementById("theme-toggle");

    const handleThemeToggle = () => {
      themeToggleDarkIcon.classList.toggle("hidden");
      themeToggleLightIcon.classList.toggle("hidden");

      if (localStorage.getItem("color-theme")) {
        if (localStorage.getItem("color-theme") === "light") {
          document.documentElement.classList.add("dark");
          localStorage.setItem("color-theme", "dark");
        } else {
          document.documentElement.classList.remove("dark");
          localStorage.setItem("color-theme", "light");
        }
      } else {
        if (document.documentElement.classList.contains("dark")) {
          document.documentElement.classList.remove("dark");
          localStorage.setItem("color-theme", "light");
        } else {
          document.documentElement.classList.add("dark");
          localStorage.setItem("color-theme", "dark");
        }
      }
    };

    themeToggleBtn.addEventListener("click", handleThemeToggle);

    return () => {
      themeToggleBtn.removeEventListener("click", handleThemeToggle);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const extractedUserID = urlParams.get("userID");
    setUserID(extractedUserID);
  }, []);

  useEffect(() => {
    if (userID) {
      const fetchCompanyName = async () => {
        try {
          const userRef = doc(db, "users", userID);
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            setCompanyName(docSnap.data().companyName);
          }
        } catch (error) {
          console.error("Failed to fetch user subscription plan:", error);
        }
      };
      fetchCompanyName();
    }
  }, [userID]);

  return (
    <nav className='absolute h-[10vh] w-screen z-50'>
      {/* {userID && (
        <ProgressBar conversationStage={conversationStage} userID={userID} />
      )} */}
      <div className='flex justify-between items-center mx-5 h-22 my-3'>
        <a href={redirectUrl}>
          <h1>← Back</h1>
        </a>
        <div className='flex flex-row items-center gap-4'>
          <div className="hidden lg:inline-flex">
            <h1 className='text-sm bg-zinc-700 py-2 px-3 rounded-xl shadow-2xl font-bold text-zinc-100'>
              {companyName}
            </h1>
          </div>
          <button
            id='theme-toggle'
            type='button'
            className='text-secondary-200 dark:text-secondary-50 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-xl text-sm p-2'>
            <svg
              id='theme-toggle-dark-icon'
              className='hidden w-5 h-5 md:w-6 md:h-6 align-middle'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'>
              <path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z' />
            </svg>
            <svg
              id='theme-toggle-light-icon'
              className='hidden w-5 h-5 md:w-6 md:h-6 align-middle text-primary-50'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z'
                fillRule='evenodd'
                clipRule='evenodd'
              />
            </svg>
          </button>
        </div>
      </div>
    </nav>
  );
};

NavBar.propTypes = {
  conversationStage: PropTypes.number.isRequired,
};

export default NavBar;
