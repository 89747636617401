import { create } from 'zustand';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../Firebase';

const useUrlStore = create((set) => ({
  redirectUrl: 'https://themattressai.com',
  setRedirectUrlListener: (userId) => {
    const docRef = doc(db, `/users/${userId}/custom_config/config`);
    return onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists() && docSnapshot.data().redirectUrl) {
        set({ redirectUrl: docSnapshot.data().redirectUrl });
      }
    });
  },
}));

export default useUrlStore;
