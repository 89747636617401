import { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import useAnimationStore from '../stores/useAnimationStore';

const AnimatedText = ({ text = '', delay = 6.5, onComplete }) => {
  const [visibleText, setVisibleText] = useState('');
  const setIsAnimating = useAnimationStore((state) => state.setIsAnimating);

  useEffect(() => {
    setVisibleText('');
    setIsAnimating(true);

    let i = 0;
    const intervalId = setInterval(() => {
      setVisibleText(text.slice(0, i));
      i++;

      if (i > text.length) {
        clearInterval(intervalId);
        setIsAnimating(false);
        if (onComplete) onComplete();
      }
    }, delay);

    return () => {
      clearInterval(intervalId);
      setIsAnimating(false);
    };
  }, [text, delay, onComplete, setIsAnimating]);

  return <span style={{whiteSpace: 'pre-line'}} className='text-zinc-600 dark:text-zinc-400 text-sm lg:text-base'>{visibleText}</span>;
};

AnimatedText.propTypes = {
  text: PropTypes.string.isRequired,
  delay: PropTypes.number,
  onComplete: PropTypes.func,
};

export default AnimatedText;
