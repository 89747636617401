/* eslint-disable react/prop-types */
import { useEffect } from "react";
import useUrlStore from "../stores/useUrlStore";

const EndSessionModal = ({ setSessionExpired }) => {
  const redirectUrl = useUrlStore((state) => state.redirectUrl);
  const setRedirectUrlListener = useUrlStore((state) => state.setRedirectUrlListener);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const extractedUserID = urlParams.get("userID");

    if (extractedUserID) {
      const unsubscribe = setRedirectUrlListener(extractedUserID);

      return () => unsubscribe();
    }
  }, [setRedirectUrlListener]);
  
  const handleClose = () => {
    setSessionExpired(false);
    setTimeout(() => {
      window.location.replace(redirectUrl);
    }, 0);
  };

  return (
    <div id="popup-modal" tabIndex="-1" className="fixed top-0 left-0 right-0 bottom-0 z-50 p-4 overflow-x-hidden overflow-y-auto flex items-center justify-center"
    style={{backgroundColor: 'rgba(0,0,0,0.5)', backdropFilter: 'blur(5px)'}}>
      <div className="relative bg-white rounded-lg shadow dark:bg-zinc-700 max-w-md px-6 py-4 mx-5">
          <div className="text-center">
              <svg className="mx-auto mb-4 text-zinc-400 w-12 h-12 dark:text-zinc-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
              </svg>
              <h3 className="mb-5 text-lg font-normal text-zinc-500 dark:text-zinc-400">Your session has ended and you will now be redirected to our homepage. Thank you for chatting with MattressAI. Sleep well!</h3>
              <button onClick={handleClose} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                  Close Session
              </button>
          </div>
      </div>
    </div>
  )
}

export default EndSessionModal;
