import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import ChatInput from "./ChatInput.jsx";
import ChatSummary from "./ChatSummary.jsx";

import EndSessionModal from "./EndSessionModal.jsx";
import DisclaimerModal from "./DisclaimerModal.jsx";
import EndChatButton from "./EndChatButton.jsx";

import Hero from "./Hero.jsx";

import "./loader.css";
import EndGradient from "./EndGradient.jsx";
import StartGradient from "./StartGradient.jsx";
import useAnimationStore from "../stores/useAnimationStore.js";

const ChatApp = ({ setConversationStage }) => {
  const [chatLog, setChatLog] = useState([]);
  const [token, setToken] = useState(null);
  const [userID, setUserID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [acknowledged, setAcknowledged] = useState(false);
  const location = useLocation();
  const [sessionExpired, setSessionExpired] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [conversationStage, setConversationStage2] = useState(0);
  const { waitingForMessage, setWaitingForMessage } = useAnimationStore();
  const [loaderMessages, setLoaderMessages] = useState({});

  const handleCloseDisclaimer = () => {
    setShowDisclaimer(false);
    setAcknowledged(true);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");
    const extractedUserID = urlParams.get("userID");

    setToken(token);
    setUserID(extractedUserID);

    const serverUrl = import.meta.env.VITE_APP_SERVER_URL;

    const path = new URL(location.pathname, `${window.location.origin}`)
      .pathname;

    // Determine the endpoint based on the path
    let endpoint;
    if (path === "/sales") {
      endpoint = "/validate-chat-sale";
    } else if (path === "/lead") {
      endpoint = "/validate-chat-lead";
    } else if (path === "/demo") {
      endpoint = "/validate-chat-demo";
    } else {
      // Handle other cases or throw an error if the path is unexpected
      console.error(`Unexpected path: ${path}`);
      return;
    }

    if (token && acknowledged) {
      fetch(`${serverUrl}${endpoint}?token=${token}&userID=${extractedUserID}`)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.message) {
            setChatLog([{ message: data.message, sender: "bot" }]);
            setTimeout(() => {
              setLoading(false);
            }, 100);
          } else {
            console.warn("Unexpected data structure:", data);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log("Fetch error:", error);
          setLoading(false);
        });
    }
  }, [acknowledged, location.pathname, location.search]);

  useEffect(() => {
    if (
      token &&
      chatLog.length > 1 &&
      chatLog[chatLog.length - 1].sender === "bot" &&
      typeof chatLog[chatLog.length - 1].message !== "string"
    ) {
      let userMessage = chatLog[chatLog.length - 2]?.message;

      if (userMessage) {
        setWaitingForMessage(true);
        const serverUrl = import.meta.env.VITE_APP_SERVER_URL;

        fetch(`${serverUrl}/chat-response?userID=${userID}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            message: userMessage,
          }),
        })
          .then((response) => {
            if (response.status === 401) {
              setSessionExpired(true);
              return;
            }
            return response.json();
          })
          .then((data) => {
            if (data) {
              setChatLog((oldChatLog) => {
                let newChatLog = oldChatLog.slice(0, -1);
                newChatLog.push({ message: data.message, sender: "bot" });
                return newChatLog;
              });

              setConversationStage(Number(data.conversation_stage));
              setConversationStage2(Number(data.conversation_stage));
            }
            setWaitingForMessage(false);
          })
          .catch((error) => {
            console.error("Error:", error);
            setWaitingForMessage(false);
          });
      }
    }
  }, [
    chatLog,
    token,
    setConversationStage,
    location.search,
    userID,
    setWaitingForMessage,
  ]);

  const handleMessageSubmit = (message) => {
    if (message.trim() !== "") {
      setChatLog((oldChatLog) => [
        ...oldChatLog,
        { message, sender: "user" },
        {
          message: (
            <div>
              <span className='loader w-[10vh] mt-4 p-4 invert opacity-70 dark:invert-0 dark:opacity-100'></span>
            </div>
          ),
          sender: "bot",
        },
      ]);
    }
  };

  return (
    <div className='flex justify-center items-center'>
      <StartGradient />
      <div className='md:w-[70%] xl:w-[50%]'>
        <div className='chat-app w-full'>
          {showDisclaimer && (
            <DisclaimerModal onClose={handleCloseDisclaimer} userID={userID} />
          )}
          <div>
            {loading && (
              <div>
                <Hero />
              </div>
            )}
            {!loading && <ChatSummary chatLog={chatLog} userID={userID} />}
          </div>
          {!loading && (
            <div className='w-full relative'>
              <div className='absolute bottom-4 z-50 w-full'>
                <EndChatButton
                  conversationStage={conversationStage}
                  token={token}
                  userID={userID}
                />
                <ChatInput onSubmit={handleMessageSubmit} />
              </div>
            </div>
          )}
          {sessionExpired && (
            <EndSessionModal setSessionExpired={setSessionExpired} />
          )}
        </div>
      </div>
      <EndGradient />
    </div>
  );
};

ChatApp.propTypes = {
  setConversationStage: PropTypes.func.isRequired,
};

export default ChatApp;
