import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { db } from "../Firebase";
import { doc, getDoc } from "firebase/firestore";
import NonLoggedInHero from "./components/NonLoggedInHero";
import Hero from "./components/Hero";

const WithSubscriptionCheck = ({ children }) => {
  const location = useLocation();
  const [currentTier, setCurrentTier] = useState(null);
  const [userID, setUserID] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const extractedUserID = urlParams.get("userID");
    setUserID(extractedUserID);

    const fetchSubscriptionPlan = async () => {
      if (!userID) return;

      try {
        const userRef = doc(db, "users", userID);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          setCurrentTier(docSnap.data().subscriptionPlan);
        }
      } catch (error) {
        console.error("Failed to fetch user subscription plan:", error);
      }
      setIsLoading(false);
    };

    fetchSubscriptionPlan();
  }, [userID, location.search]);

  // If loading, display the spinner animation
  if (isLoading) {
    return (
      <div>
        <Hero />
      </div>
    );
  }

  // Check if path is '/demo'
  if (location.pathname === "/demo") {
    return children;
  }

  // Check if user has a "Premium Plan" and is accessing "/sales" route
  if (location.pathname === "/sales" && currentTier !== "Premium Plan" && currentTier !== "Unlimited Plan" && currentTier !== "Launchpad Plan") {
    return <NonLoggedInHero />;
  }

  // If user has a valid subscription, render the children components
  if (currentTier) {
    return children;
  }

  // For all other conditions, redirect to the NonLoggedInHero component
  return <NonLoggedInHero />;
};

export default WithSubscriptionCheck;
