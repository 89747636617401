import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useAnimationStore from "../stores/useAnimationStore";
import DOMPurify from "dompurify";
import * as Sentry from "@sentry/react";
import ToastError from "./ToastError";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../Firebase";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  QuestionMarkCircleIcon,
  GiftIcon,
  FaceFrownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

const promptData = [
  {
    title: "I need a new mattress.",
    icon: GiftIcon,
    prompt: "I need a new mattress, can you help me find one ?",
  },
  {
    title: "I'm not sure where to start.",
    icon: QuestionMarkCircleIcon,
    prompt: "I'm not quite sure where to start, can you help ?",
  },
  {
    title: "I hate my current mattress.",
    icon: FaceFrownIcon,
    prompt:
      "I hate my current mattress, I was hoping you could help me find a new one.",
  },
  {
    title: "I have a mattress in mind.",
    icon: MagnifyingGlassIcon,
    prompt:
      "I'm looking for a new mattress, I have some ideas of the brand or model I want. Can you help ?",
  },
];

const ChatInput = ({ onSubmit }) => {
  const [message, setMessage] = useState("");
  const { isAnimating, waitingForMessage } = useAnimationStore();
  const isDisabled = isAnimating || waitingForMessage;
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [invalidAttempts, setInvalidAttempts] = useState(0);
  const [hasPromptBeenClicked, setHasPromptBeenClicked] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 768);

  const isInputValid = (input) => {
    if (input.length === 0 || input.length > 500) return false;

    const regex = /<|>|;|--|\*|\/|:|=|\[|\\|\]|\^|`|\{|\||\}|~/;
    return !regex.test(input);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const trimmedMessage = message.trim();

    if (!isInputValid(trimmedMessage)) {
      Sentry.captureEvent({
        message: "Malicious input detected in ChatInput",
        level: "warning",
        extra: {
          input: trimmedMessage,
        },
      });

      setShowErrorToast(true);
      setInvalidAttempts((prev) => prev + 1);
    } else {
      const sanitizedMessage = DOMPurify.sanitize(trimmedMessage);
      onSubmit(sanitizedMessage);
      setMessage("");
      setShowErrorToast(false);
      setHasPromptBeenClicked(true);
    }
  };

  const fetchIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP:", error);
    }
  };

  useEffect(() => {
    const saveIPtoFirebase = async (ipAddress) => {
      try {
        const ipRef = doc(
          db,
          "security",
          "attacks",
          "blacklisted_ips",
          ipAddress
        );
        await setDoc(ipRef, { ip: ipAddress, timestamp: serverTimestamp() });
      } catch (error) {
        console.error("Error saving IP to Firebase:", error);
      }
    };

    if (invalidAttempts >= 5) {
      fetchIP().then((ipAddress) => {
        if (ipAddress) saveIPtoFirebase(ipAddress);
      });
    }
  }, [invalidAttempts]);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    if (showErrorToast) {
      const timer = setTimeout(() => setShowErrorToast(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showErrorToast]);

  const handleOptionClick = (prompt) => {
    onSubmit(prompt);
    setHasPromptBeenClicked(true);
  };

  return (
    <>
      {showErrorToast && (
        <ToastError message='Unexpected characters detected. Please refrain from adding special characters.' />
      )}
      <form
        onSubmit={handleSubmit}
        className='justify-center items-center w-full px-3 lg:px-1 z-0 relative'>
        {!hasPromptBeenClicked && (
          <div className='prompt-container'>
            {isMobileScreen ? (
              <div className='hover:cursor-pointer absolute max-w-md left-0 right-0 mx-auto bottom-12 my-4 px-8 transform -translate-y-1/2'>
                <Slider {...settings}>
                  {promptData.map((option, index) => (
                    <div
                      key={index}
                      className='bg-zinc-100/50 lg:hover:bg-zinc-300/40 hover:cursor-pointer dark:bg-zinc-700/60 lg:dark:hover:bg-zinc-900/30 transition-all duration-150 ease-in-out p-2 rounded-xl border border-zinc-300/80 dark:border-zinc-500/80 shadow-sm'
                      onClick={() => handleOptionClick(option.prompt)}>
                      <div className='flex flex-row items-center gap-1 lg:gap-4'>
                        <option.icon className='w-5 h-5 lg:w-6 lg:h-6' />
                        <h1 className='text-xs lg:text-sm dark:text-white text-center'>
                          {option.title}
                        </h1>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <div className='absolute left-0 right-0 mx-auto bottom-4 my-4 px-4 lg:px-16 transform -translate-y-1/2'>
                <div className='grid grid-cols-2 gap-2 lg:gap-4'>
                  {promptData.map((option, index) => (
                    <div
                      key={index}
                      className='bg-zinc-100/50 lg:hover:bg-zinc-300/40 hover:cursor-pointer dark:bg-zinc-700/60 lg:dark:hover:bg-zinc-900/30 transition-all duration-150 ease-in-out p-2 rounded-xl border border-zinc-300/80 dark:border-zinc-500/80 shadow-sm'
                      onClick={() => handleOptionClick(option.prompt)}>
                      <div className='flex flex-row items-center gap-1 lg:gap-4'>
                        <option.icon className='w-5 h-5 lg:w-6 lg:h-6' />
                        <h1 className='text-xs lg:text-sm dark:text-white text-center'>
                          {option.title}
                        </h1>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        <div className='flex justify-center items-center'>
          <div className='flex justify-center items-center w-full bg-zinc-200/70 dark:bg-zinc-700/60 rounded-xl border-2 py-1 lg:py-1.5 px-3 lg:px-6 border-zinc-300 dark:border-zinc-600'>
            <textarea
              value={message}
              // onChange={(event) => setMessage(event.target.value)}
              onChange={handleChange}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  handleSubmit(event);
                }
              }}
              className='hide-scrollbar flex items-center bg-transparent form-control w-full resize-none outline-none focus:bg-transparent placeholder:text-sm lg:placeholder:text-base text-sm lg:text-base p-2 h-10'
              rows='1'
              placeholder='Ask me anything...'
              disabled={isDisabled}></textarea>
            <button
              type='submit'
              className='right-44 btn btn-primary ml-4'
              disabled={isDisabled}>
              {isAnimating || waitingForMessage ? (
                <svg
                  className='w-8 h-8 animate-pulse'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={1.5}
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636'
                  />
                </svg>
              ) : (
                <svg
                  className='w-6 h-6 lg:w-8 lg:h-8 hover:-rotate-90 transition-all duration-200 ease-in-out hover:text-zinc-500 dark:hover:text-zinc-200 active:text-green-400'
                  aria-hidden='true'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'></path>
                </svg>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

ChatInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ChatInput;
