/* eslint-disable react/no-unescaped-entities */
const Hero = () => {
  return (
    <div className='h-[75vh]'>
      <div className='h-[100%]'>
        <div className='flex justify-center items-center w-full h-full'>
          <div className='mx-6 w-full sm:w-[50vw] lg:max-w-sm mt-[20vh] shadow-2xl rounded-xl bg-zinc-50 dark:bg-zinc-800 p-8 border border-zinc-400/50 dark:border-zinc-600 z-20'>
            <div className='mb-6 flex flex-col justify-center items-center gap-4 h-full text-zinc-700 dark:text-white'>
              <div className='gradient-animation rounded-xl shadow-green'>
                <img
                  src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
                  className='w-12 h-12 lg:w-14 lg:h-14 bg-zinc-700 rounded-lg m-1'
                />
              </div>
            </div>
            <div className='p-4'>
              <div className='flex justify-center items-center'>
                <span className='loader3 max-w-sm bg-zinc-300 rounded-xl dark:bg-zinc-700'></span>
              </div>
            </div>
            <div>
              <p className='text-xs text-center font-thin text-zinc-500/90 mt-6'>
                Powered By <span className='font-bold'>MattressAI</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
