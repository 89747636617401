/* eslint-disable react/prop-types */
import { useEffect } from "react";
import useUrlStore from "../stores/useUrlStore";
import axios from "axios";

const ChatEndModal = ({ setSessionExpired, token, userID }) => {
  const redirectUrl = useUrlStore((state) => state.redirectUrl);
  const setRedirectUrlListener = useUrlStore((state) => state.setRedirectUrlListener);

    const path = window.location.pathname;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const extractedUserID = urlParams.get("userID");

    if (extractedUserID) {
      const unsubscribe = setRedirectUrlListener(extractedUserID);

      return () => unsubscribe();
    }
  }, [setRedirectUrlListener]);

  const handleEnd = async () => {
    const serverUrl = import.meta.env.VITE_APP_SERVER_URL;
    setTimeout(() => {
      window.location.replace(redirectUrl);
    }, 0);

    let endpoint;
    if (path === "/sales") {
      endpoint = "/chat-end-sale";
    } else if (path === "/lead") {
      endpoint = "/chat-end-lead";
    } else if (path === "/demo") {
      endpoint = "/chat-end-demo";
    } else {
      console.error(`Unexpected path: ${path}`);
      return;
    }

    try {
      const result = await axios.post(
        `${serverUrl}${endpoint}?userID=${userID}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCloseModal = () => {
    setSessionExpired(false);
  };

  return (
    <div id="popup-modal" tabIndex="-1" className="fixed top-0 left-0 right-0 bottom-0 z-50 p-4 overflow-x-hidden overflow-y-auto flex items-center justify-center"
    style={{backgroundColor: 'rgba(0,0,0,0.5)', backdropFilter: 'blur(5px)'}}>
      <div className="relative bg-white rounded-lg shadow dark:bg-zinc-700 max-w-md px-6 py-4 mx-5">
          <div className="text-center">
              <svg className="mx-auto mb-4 text-zinc-400 w-12 h-12 dark:text-zinc-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
              </svg>
              <h3 className="mb-5 text-base lg:text-lg font-normal text-zinc-500 dark:text-zinc-400">Are you sure you want to end your conversation?</h3>
              <h3 className="mb-5 text-sm lg:text-lg font-normal text-zinc-400 dark:text-zinc-400/80"> You will be redirected.</h3>
              <div className="flex flex-col justify-center gap-2 lg:gap-4 lg:flex-row w-full text-center">
                <button onClick={handleCloseModal} type="button" className="flex justify-center items-center text-white bg-zinc-800 hover:bg-zinc-900 focus:ring-4 focus:outline-none focus:ring-zinc-300 dark:focus:ring-zinc-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                  <h1 className="text-sm lg:text-base">Take Me Back</h1>
                </button>
                <button onClick={handleEnd} type="button" className="flex justify-center items-center text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                  <h1 className="text-sm lg:text-base">End Session</h1>
                </button>
              </div>
          </div>
      </div>
    </div>
  )
}

export default ChatEndModal;
