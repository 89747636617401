import { useEffect, useRef, useState, useCallback } from "react";
import { doc, getDoc } from "firebase/firestore";
import AnimatedText from "./AnimatedText";
import PropTypes from "prop-types";
import { db } from "../../Firebase";

const ChatSummary = ({ chatLog, userID }) => {
  const messagesEndRef = useRef(null);
  const [avatars, setAvatars] = useState({});
  const [salesPersonName, setSalesPersonName] = useState("");

  useEffect(() => {
    const fetchSalesPersonName = async () => {
      const salesPersonNameRef = doc(
        db,
        "users",
        userID,
        "custom_config",
        "config"
      );
      const docSnap = await getDoc(salesPersonNameRef);

      if (docSnap.exists() && docSnap.data().salesPersonName) {
        setSalesPersonName(docSnap.data().salesPersonName);
      }
    };

    fetchSalesPersonName();
  }, [userID]);

  useEffect(() => {
    const newAvatars = {};
    for (const entry of chatLog) {
      if (entry.sender === "user") {
        // Assign the specific user avatar URL
        newAvatars[entry.id] =
          "https://res.cloudinary.com/dkbn21xdu/image/upload/v1720272080/dfrgfasd_x26u9o.png";
      } else {
        // Assign the specific bot avatar URL
        newAvatars[entry.id] =
          "https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png";
      }
    }
    setAvatars(newAvatars);
  }, [chatLog]);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(scrollToBottom, [chatLog]);

  return (
    <div className='h-[calc(100dvh)] pb-6 -z-50 hide-scrollbar px-2 lg:px-0'>
      <div className='h-[100%] -z-50 flex flex-col justify-center items-center hide-scrollbar'>
        <div className='relative w-full hide-scrollbar'>
          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-12 z-50'>
            <div className='flex flex-col justify-center items-center gap-4 my-0.5 z-50'>
              <div className='gradient-animation rounded-xl dark:shadow-green z-50'>
                <img
                  src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
                  className='w-11 h-11 dark:bg-zinc-700 bg-zinc-700 rounded-lg m-1'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='overflow-auto h-full space-y-8 lg:pl-10 px-12 lg:px-4 py-16 lg:p-16 w-full hide-scrollbar'>
          {chatLog.map((entry, index) => {
            return (
              <div
                key={index}
                className='flex flex-col mt-14 items-start relative hide-scrollbar'>
                <div className='absolute -left-10 lg:-left-10 top-[5%]'>
                  <div
                    className={`flex flex-row gap-3 items-center p-0.5 rounded-full ${
                      entry.sender === "user"
                        ? "bg-zinc-300 dark:bg-zinc-700"
                        : "bg-gradient-to-r from-blue-400 to-green-400"
                    }`}>
                    <img
                      src={
                        entry.sender === "user"
                          ? "https://res.cloudinary.com/dkbn21xdu/image/upload/v1720272080/dfrgfasd_x26u9o.png"
                          : "https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png"
                      }
                      className={`w-8 lg:w-9 h-8 lg:h-9 rounded-full ${
                        entry.sender === "user" ? "p-2" : ""
                      } bg-zinc-600 dark:bg-zinc-800`}
                      alt='Avatar'
                    />
                  </div>
                </div>
                <div className='px-4 py-2 rounded-lg text-zinc-800 dark:text-white text-md lg:text-lg font-normal max-w-[80vw] lg:max-w-[100%] whitespace-normal break-words'>
                  <p className='capitalize text-xl font-semibold'>
                    {entry.sender === "user" ? "You" : salesPersonName || "Bot"}
                  </p>
                  {entry.sender === "user" ? (
                    <p className="text-zinc-600 dark:text-zinc-400 text-sm lg:text-base">{entry.message}</p>
                  ) : typeof entry.message === "string" ? (
                    <AnimatedText
                      text={entry.message}
                      onComplete={scrollToBottom}
                    />
                  ) : (
                    entry.message
                  )}
                </div>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>
      </div>
    </div>
  );
};

ChatSummary.propTypes = {
  chatLog: PropTypes.array.isRequired,
  userID: PropTypes.string.isRequired,
};

export default ChatSummary;
