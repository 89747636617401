import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { db } from "../../Firebase";
import { doc, getDoc } from "firebase/firestore";

const DisclaimerModal = ({ onClose, userID }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [salesPersonName, setSalesPersonName] = useState("");

  useEffect(() => {
    // Fetch company name
    const fetchCompanyName = async () => {
      const docRef = doc(db, "users", userID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const companyName = docSnap.data().companyName;
        setCompanyName(companyName);
      } else {
        console.log("No Company Name Found!");
      }
    };

    // Fetch sales person name
    const fetchSalesPersonName = async () => {
      const configRef = doc(db, `users/${userID}/custom_config/config`);
      const configSnap = await getDoc(configRef);

      if (configSnap.exists()) {
        const salesPerson = configSnap.data().salesPersonName;
        setSalesPersonName(salesPerson);
      } else {
        console.log("No Sales Person Name Found!");
      }
    };

    fetchCompanyName().catch((error) => {
      console.log("Error getting company document:", error);
    });

    fetchSalesPersonName().catch((error) => {
      console.log("Error getting sales person document:", error);
    });
  }, [userID]);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      id='popup-modal'
      tabIndex='-1'
      className='fixed top-0 left-0 right-0 bottom-0 z-50 p-4 overflow-x-hidden overflow-y-auto flex items-center justify-center bg-zinc-400 dark:bg-black bg-opacity-60 backdrop-blur-sm'>
      <div className='relative flex flex-col justify-center items-center bg-zinc-50 rounded-xl shadow dark:bg-zinc-800 max-w-md px-6 py-6 mx-5 border border-zinc-300 dark:border-zinc-600'>
        <div className='text-center flex flex-col justify-center items-center'>
          <div className='flex flex-col justify-center items-center gap-4 h-full text-zinc-700 dark:text-white mb-4'>
            <div className='gradient-animation rounded-xl shadow-green'>
              <img
                src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
                className='w-12 h-12 bg-zinc-700 rounded-lg m-1'
              />
            </div>
          </div>
          <div className='flex flex-col gap-4 items-center justify-center mt-4'>
            <div>
              {salesPersonName && (
                <p className='text-zinc-700 dark:text-zinc-100 font-bold text-base lg:text-lg'>
                  Hello I&apos;m {salesPersonName}.
                </p>
              )}
              {companyName && (
                <p className='text-zinc-500 dark:text-zinc-200 text-xs lg:text-base'>
                  An AI-Powered Assistant by {companyName}.
                </p>
              )}
            </div>
            <hr className='w-3/4 border-zinc-300 dark:border-zinc-600' />
            <div>
              <h3 className='text-xs lg:text-sm font-normal text-zinc-500 dark:text-zinc-500 mx-4 mb-2'>
                I am designed to guide you towards the best mattress choices
                based on your individual needs.{" "}
              </h3>
              <h3 className='mb-5 text-xs lg:text-sm font-normal text-zinc-500 dark:text-zinc-500 mx-4'>
                You&apos;re conversing with an artificial intelligence, here to
                help streamline your mattress selection process.
              </h3>
            </div>
          </div>
          <button
            onClick={handleClose}
            type='button'
            className='w-1/3 mt-2 text-white bg-zinc-600 hover:bg-zinc-700 font-medium rounded-lg text-sm px-5 py-1 text-center'>
            <div className='w-full flex flex-row justify-center items-center gap-4'>
              <h3 className='text-base lg:text-lg font-semibold'>Start</h3>
              <svg
                className='w-5 h-5'
                fill='none'
                stroke='currentColor'
                strokeWidth='1.5'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3'></path>
              </svg>
            </div>
          </button>
        </div>
        <div>
          <p className='text-xs font-thin text-zinc-500/90 mt-6'>
            Powered By <span className='font-bold'>MattressAI</span>
          </p>
        </div>
      </div>
    </div>
  );
};

DisclaimerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  userID: PropTypes.string.isRequired,
};

export default DisclaimerModal;
