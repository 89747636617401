import { create } from 'zustand';

const useAnimationStore = create((set) => ({
  isAnimating: false,
  setIsAnimating: (isAnimating) => set({ isAnimating }),
  waitingForMessage: false,
  setWaitingForMessage: (waitingForMessage) => set({ waitingForMessage })
}));

export default useAnimationStore;
