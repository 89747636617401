import PropTypes from "prop-types";
import { useState } from "react";
import ChatEndModal from "./ChatEndModal";

const EndChatButton = ({ conversationStage, token, userID }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const path = window.location.pathname;

  if (
    (path === "/sales" && conversationStage < 5) ||
    (path === "/lead" && conversationStage < 2)
  ) {
    return null;
  }

  const handleEndChat = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='relative w-full'>
      <button
        title="End Chat"
        onClick={handleEndChat}
        className='absolute -top-12 right-3 lg:right-1 bg-gradient-to-r text-zinc-500 dark:text-white hover:text-white bg-zinc-200/70 hover:bg-red-500 dark:bg-zinc-700 hover:dark:bg-red-500 border-2 border-zinc-300 dark:border-zinc-600 p-1.5 text-sm rounded-xl transition-all duration-300 ease-in-out'>
        <svg
          className="w-6 h-6 dark:text-zinc-200 hover:text-white"
          fill='none'
          stroke='currentColor'
          strokeWidth={1.5}
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'>
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
          />
        </svg>
      </button>
      {isModalOpen && <ChatEndModal setSessionExpired={handleModalClose} userID={userID} token={token} />}
    </div>
  );
};

EndChatButton.propTypes = {
  conversationStage: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  userID: PropTypes.string.isRequired,
};

export default EndChatButton;
