/* eslint-disable react/no-unescaped-entities */
const NonLoggedInHero = () => {
  return (
    <div className="h-[75vh]">
      <div className="h-[100%]">
        <div className="flex flex-col justify-center items-center gap-4 h-full text-zinc-700 dark:text-white">
          <div className="gradient-animation rounded-xl shadow-green">
            <img src="https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png" className="w-32 h-32 bg-zinc-700 rounded-xl m-2" />
          </div>
          <h1 className="text-5xl font-black mt-8">MattressAI</h1>
          <h2 className="max-w-md mx-10 md:mx-0 text-center">Uh Oh, Looks like you haven't joined a valid chat session, <span className="font-bold">Try a different link, or contact the store manager.</span></h2>
        </div>
      </div>
    </div>
  );
};

export default NonLoggedInHero;
