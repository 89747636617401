import PropTypes from 'prop-types';

const ToastError = ({ message }) => {
  return (
    <div className="absolute -top-[91vh] right-3 md:-top-[90vh] md:-right-[13vw] lg:-top-[90vh] lg:-right-[13vw] xl:-top-[90vh] xl:-right-[23vw] 2xl:-top-[90vh] 2xl:-right-[24vw] z-50">
      <div
        id='toast-success'
        className='flex items-center w-[70vw] md:w-full md:max-w-md p-4 mb-4 text-zinc-500 bg-white rounded-lg dark:text-zinc-400 dark:bg-zinc-900 shadow-2xl'
        role='alert'>
        <div className='inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200'>
          <svg
            className='w-5 h-5'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            stroke='currentColor'
            strokeWidth='2'
            fill="none"
            viewBox='0 0 24 24'>
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"></path>
          </svg>
          <span className='sr-only'>Check icon</span>
        </div>
        <div className='ml-3 text-xs md:text-sm lg:text-base font-normal'>{message}</div>
      </div>
    </div>
  );
};

ToastError.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ToastError;
