import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";

import NavBar from "../src/components/Navbar";
import ChatApp from "../src/components/ChatApp";
import NonLoggedInHero from "./components/NonLoggedInHero";

import WithSubscriptionCheck from "./WithSubscriptionCheck";

function App() {
  const [conversationStage, setConversationStage] = useState(0);

  return (
    <>
      <div className='bg-zinc-200 dark:bg-zinc-800 h-[calc(100dvh)] z-50 overflow-hidden text-zinc-800 dark:text-zinc-100'>
        <Router>
          <NavBar conversationStage={conversationStage} />
          <Routes>
            <Route path='/' element={<NonLoggedInHero />} />
            <Route
              path='/sales'
              element={
                <WithSubscriptionCheck>
                  <ChatApp setConversationStage={setConversationStage} />
                </WithSubscriptionCheck>
              }
            />
            <Route
              path='/lead'
              element={
                <WithSubscriptionCheck>
                  <ChatApp setConversationStage={setConversationStage} />
                </WithSubscriptionCheck>
              }
            />
            <Route
              path='/demo'
              element={<ChatApp setConversationStage={setConversationStage} />}
            />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
